import { Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useAuth } from "./authContext/AuthContext";
import { Navigate } from "react-router-dom";

// home pages
const Home = lazy(() => import("./pages/Home"));
const AdminDashboard = lazy(() => import("./pages/AdminDashboard"));

// other pages
const Contact = lazy(() => import("./pages/Contact"));
const MyAccount = lazy(() => import("./pages/MyAccount"));
const Trading = lazy(() => import("./pages/Trading"));
const Profile = lazy(() => import("./pages/Profile"));
const TotalMembers = lazy(() => import("./pages/admin-pages/TotalMembers"));
const LoginRegister = lazy(() => import("./pages/LoginRegister"));

const NotFound = lazy(() => import("./pages/NotFound"));

const App = () => {
  const { user, userData } = useAuth();

  return (
    <Router>
      <ScrollToTop>
        <Suspense
          fallback={
            <div className="flone-preloader-wrapper">
              <div className="flone-preloader">
                <span></span>
                <span></span>
              </div>
            </div>
          }
        >
          <Routes>
            {/* Homepages */}
            <Route path={process.env.PUBLIC_URL + "/"} element={<Home />} />

            <Route
              path={process.env.PUBLIC_URL + "/admin-dashboard"}
              element={
                user && userData && userData.userType === "admin" ? (
                  <AdminDashboard />
                ) : (
                  <Navigate to="/login-register" />
                )
              }
            />

            {/* Other pages */}

            <Route
              path={process.env.PUBLIC_URL + "/contact"}
              element={<Contact />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/trading"}
              element={
                user && userData ? (
                  <Trading />
                ) : (
                  <Navigate to="/login-register" />
                )
              }
            />

            <Route
              path={process.env.PUBLIC_URL + "/total-members"}
              element={
                user && userData.userType === "admin" ? (
                  <TotalMembers />
                ) : (
                  <Navigate to="/login-register" />
                )
              }
            />

            <Route
              path={process.env.PUBLIC_URL + "/profile"}
              element={
                user && userData ? (
                  <Profile />
                ) : (
                  <Navigate to="/login-register" />
                )
              }
            />
            <Route
              path={process.env.PUBLIC_URL + "/my-account"}
              element={
                user && userData ? (
                  <MyAccount />
                ) : (
                  <Navigate to="/login-register" />
                )
              }
            />
            <Route
              path={process.env.PUBLIC_URL + "/login-register"}
              element={<LoginRegister />}
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
};

export default App;
