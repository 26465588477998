import React, { createContext, useContext, useState, useEffect } from "react";
import { auth, db } from "../firebaseConfig/FirebaseConfig";
import secureLocalStorage from "react-secure-storage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const idToken = await user.getIdToken();
          const userDocRef = db.collection("users").doc(user.uid);
          const unsubscribeUserData = userDocRef.onSnapshot((doc) => {
            if (doc.exists) {
              secureLocalStorage.setItem("user", idToken);
              secureLocalStorage.setItem("userData", doc.data());
              setUserData(doc.data());
              setUser(user);
            }
          });

          return () => unsubscribeUserData();
        } catch (error) {
          console.error("Error fetching user data:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setUser(null); // Ensure user state is null when no user is logged in
        setUserData(null);
        setLoading(false);
      }
    });

    // Check if user was previously logged in
    const storedUser = secureLocalStorage.getItem("user");
    const storedUserData = secureLocalStorage.getItem("userData");
    if (storedUser && storedUserData) {
      setUser(storedUser);
      setUserData(storedUserData);
    }

    return () => unsubscribe();
  }, []);

  const login = async (email, password) => {
    setLoading(true);
    try {
      await auth.signInWithEmailAndPassword(email, password);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const signup = async (name, number, email, password, referredBy) => {
    setLoading(true);
    try {
      const userCredential = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
      const { user } = userCredential;

      // Function to generate a unique referral code
      const generateReferralCode = () => {
        // Get current timestamp (milliseconds since Unix epoch)
        const timestamp = new Date().getTime().toString(36).toUpperCase(); // Convert to base36 string

        // Generate random characters
        const characters =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const randomChars = Array.from({ length: 4 }, () =>
          characters.charAt(Math.floor(Math.random() * characters.length))
        );

        // Combine timestamp and random characters
        return `${timestamp}${randomChars.join("")}`;
      };

      await db.collection("users").doc(user.uid).set({
        email,
        name,
        number,
        referredBy,
        referralCode: generateReferralCode(),
        uid: user.uid,
        depositWalletBalance: 0,
        userType: "user",
        accountCreatedAt: new Date(),
      });

      return user;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    try {
      await auth.signOut();
      secureLocalStorage.removeItem("user"); // Clear stored user data on logout
      secureLocalStorage.removeItem("userData");
      setUser(null);
      setUserData(null);
    } catch (error) {
      throw error;
    }
  };

  return (
    <AuthContext.Provider
      value={{ user, userData, login, signup, logout, loading }}
    >
      {loading && (
        <div className="flone-preloader-wrapper">
          <div className="flone-preloader">
            <span></span>
            <span></span>
          </div>
        </div>
      )}
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
